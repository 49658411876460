<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <logo />
        </b-link>

        <!-- form -->
        <validation-observer ref="loginForm" #default="{invalid}">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
            autocomplete="off"
          >
            <!-- email -->
            <b-form-group label-for="email" label="เบอร์โทรศัพท์">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="username"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="ระบุเบอร์ เช่น 0987652425"
                  autofocus
                />
                <small class="text-danger">{{
                  errors[0] ? 'กรุณาระบุเบอร์โทรศัพท์์' : ''
                }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <!-- <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{ name: 'auth-forgot-password-v1' }">
                  <small>Forgot Password?</small>
                </b-link>
              </div> -->
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="รหัสผ่าน"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{
                  errors[0] ? 'กรุณาระบุรหัสผ่าน' : ''
                }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="onLoggedIn"
            >
              เข้าสู่ระบบ
            </b-button>
          </b-form>
        </validation-observer>

        <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div>
        <b-card-text class="text-center mt-2">
          <span>หากลูกค้ายังไม่มีบัญชี? </span>
          <b-link :to="{ name: 'register' }">
            <span>คลิกเพื่อเปิดบัญชี</span>
          </b-link>
        </b-card-text>
        <b-button
          variant="warning"
          type="submit"
          block
          :disabled="invalid"
          @click="forgotPassword"
        >
          ลืมรหัสผ่าน
        </b-button>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Logo from './component/Logo.vue'

import jwt from '../auth/jwt/useJwt'

import { setUserData, isUserLoggedIn } from '../auth/utils'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    Logo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: '',
      password: '',
      status: '',
      // validation rules
      required,
      email
    }
  },
  methods: {
    async onLoggedIn() {
      const resp = await jwt.login({
        username: this.username,
        password: this.password,
        agent_id: 27
      })
      if (resp.data.success === true) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Logged In Success',
            icon: 'EditIcon',
            variant: 'success'
          }
        })
        jwt.setToken(resp.data.token)
        setUserData(resp.data.member)
        this.$router.push('/member/lobby')
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
            icon: 'EditIcon',
            variant: 'danger'
          }
        })
      }
      // console.log('R', resp)
    },
    forgotPassword() {
      window.open('https://bit.ly/FRIZ', '_blank')
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  beforeMount() {
    if (isUserLoggedIn()) {
      this.$router.push('/member/lobby')
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
